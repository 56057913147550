<template>
  <div class="h-100">
    <apply-buttons @selectForm="showAppForm" />
  </div>
</template>

<script>
import ApplyButtons from "./Apply-Buttons";
export default {
  name: "ApplyRouter",
  components: { ApplyButtons},
  props: {
    currentLendingAmount: Number
  },
  methods: {
    showAppForm(form) {
      this.$emit('showAppForm', form);
      window.scrollTo(0, 0);
    }
  },
}
</script>