<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style scoped>
.cta-bar .title {
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}
.cta-bar .copy {
  color: #fff;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
</style>
