<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        title="We make specialist finance simple."
        leading="When you're looking for specialist finance, you need an expert on your side. Our team of specialists are
friendly and knowledgeable, and we'll help you find the finance deal that's right for you - quickly, easiliy and efficiently."
        :points="[
            `Loans from £10,000 to £20,000,000`,
            `Our expert team arranges your loan and supports you all the way`,
            `Secure your loan against residential or business property`,
            `Fast access to the finance you're looking for`
            ]"
    />
    <TrustPilot />
    <InfoSection
        image="happy-laptop.png"
        title="Our team supports you all the way."
        :swap="true"
    >
      <p>Not everyone deals in specialist and non-standard finance – but we do. Whether you want to borrow money against your mortgage-free home or you’re looking for short-term bridging finance, we can help.</p>
      <p>By combining bespoke technology with our friendly expert team, we make it really easy to find competitive quotes and get your application underway. Then we support you all the way until the money’s in your bank account.</p>
      <p>Enjoy a smooth and simple lending journey from beginning to end, and get specialist finance tailored to you.</p>
    </InfoSection>

    <InfoSection
        image="family-old"
        title="Specialist residential mortgages"
    >
      <p>When you want to borrow money against your residential property , we can help!  Whatever your circumstances or credit history, we search our panel of lenders to find competitive deals that are right for you.</p>
      <p>
        We have a panel of specialist lenders who you will not find on the high street , we offer exclusive rates and
        products which you can only obtain through a broker. Our Advisors will take the time to understand your
        requirements to find the best mortgage deal for your needs and circumstances.</p>
      <p>
        High street banks and lenders can be less flexible when it comes to lending criteria and affordability
        checks. But for our panel of specialist lenders, using your property as security is a more flexible option
        and we can help arrange finance from £5,000 up to over £1 million.</p>
      <ul>
        <li>Loans from £5,000 available</li>
        <li>Use your funds for any purpose</li>
        <li>Interest rates from 4%</li>
        <li>Got a less than perfect credit history? No problem</li>
      </ul>
    </InfoSection>

    <InfoSection
        image="sorting-boxes"
        title="Bridging finance"
        :swap="true"
    >
      <p>
        Short-term bridging finance can be a quick and easy way to borrow the funds you need –
        usually repaying over a period of up to 12 months, although this can easily be refinanced or extended.
      </p>
      <p>
        Bridging loans are a great choice for property developers and buy-to-let investors, with completion
        usually within two to four weeks of application. We’re experts in this area, searching our panel of
        lenders to find you a competitive deal that suits your circumstances.
      </p>
      <ul>
        <li>Interest rates from 0.65%</li>
        <li>Bridging loans are available for both individuals and businesses</li>
        <li>Secure your bridging loan against residential or commercial property</li>
        <li>A great solution if you’re on a tight property purchase deadline</li>
        <li>Cut out the delays and property chains that can come with a typical mortgage</li>
        <li>Your route to fast cash with payment usually within two weeks</li>
      </ul>
    </InfoSection>

    <InfoSection
        image="home-keys"
        title="Buy to let mortgages"
    >
      <p>
        Whether you want to let a single property or you have a large portfolio, we can arrange buy to let
        mortgages on all kinds of homes. From new build developments to houses in multiple occupation
        (HMOs), our panel of lenders has deals to match.
      </p>
      <ul>
        <li>
          Release up to 80% of the value of your property portfolio with our exclusive home equity flexi loans
          can – and you can drawdown whenever you wish
        </li>
        <li>Interest rates from 4.99%</li>
        <li>Do you have a fluctuating income? We can still help</li>
        <li>Buying an unusual property? Let’s take a look</li>
        <li>Been turned down elsewhere? No problem</li>
      </ul>
    </InfoSection>

    <InfoSection
        image="holiday"
        title="Equity release"
        :swap="true"
    >
      <p>
        Equity release is a way to unlock cash from the value of your home – it can help you achieve
        financial freedom in later life. Whether you want to pay for home renovations, give children
        or grandchildren a helping hand, or simply supplement your income, equity release can help.
      </p>
      <p><strong>How do interest and repayments work?</strong></p>
      <p>
        You don’t make monthly repayments with equity release. Instead, interest is added every year,
        both to the initial loan amount and to any interest previously added. This can quickly
        increase the amount you owe. The loan and interest are usually repaid when your home is
        sold, when you die, or if you need to go into long-term care.
      </p>
      <p><strong>Why choose Believe?</strong></p>
      <p>
        If equity release is right for you, we can help you find an amazing deal. We work closely with
        our preferred partners to find you the best possible deal for your circumstances. source
        the right equity release mortgage for you.
      </p>
      <p><strong>Please note that you could need to take independent legal advice.</strong></p>
    </InfoSection>

    <InfoSection
        image="business"
        title="Commercial finance"
    >
      <p>Raise money for commercial investment quickly and easily. Working closely with our preferred
        partners, we can help you access a wide range of mortgages and lenders from an extensive panel. Our
        aim is to find you the best possible deal for your circumstances, helping you unlock the
        funds you need for your commercial project.
        </p>
        <p><strong>What we offer</strong></p>
      <ul>
        <li>Finance options for retail and commercial units</li>
        <li>Raise money to buy offices or warehouses</li>
        <li>Buying hotels or licensed premises? We can help</li>
        <li>Finance available for semi-commercial properties</li>
        <li>Land and agricultural purchases also covered</li>
        <li>Manually underwritten cases</li>
        <li>Loans of up to £50 million for large transactions</li>
        <li>We can lend on complex commercial investments</li>
      </ul>
    </InfoSection>




    <HowItWorks/>
    <Contact />
    <Lenders />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import InfoSection from "../Sections/InfoSection";
import Contact from '../Sections/Contact.vue'
import Lenders from '../Sections/Lenders.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";

export default {
  name: 'app',
  components: {
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Lenders,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>
