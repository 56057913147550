<template>
  <div>
    <Header />
    <div class="container pt-4">
      <div class="row">
        <div class="col-12 complaints">
          <h1>Complaints Policy</h1>

          <h2>Your Complaint</h2>
          <p>You should address any complaint, in writing, to The Compliance Officer, {{ branding.appointed.name }},
            {{ branding.appointed.address }} (Telephone: {{ branding.appointed.phone }}) who will acknowledge the
            issue and send you a copy of the internal complaints procedure. The matter will be investigated in line
            with procedures and findings reported to you. If you are still not happy with the outcome, you then
            have the right to forward any eligible complaint to the Financial Ombudsman Service and the details
            on how to do so will be provided to you. If you make a valid claim against us in respect of any mortgage
            we arrange for you, and we are unable to meet our liabilities in full, you may be entitled to redress
            from the Financial Services Compensation Scheme. Details of the cover are given in a leaflet, which
            we will send to you at your request. Further information is available from the Financial Conduct
            Authority. In addition we are also insured for negligence by way of our Professional Indemnity Insurance.</p>

          <h2>What to do if you are dissatisfied with our response</h2>
          <p>If you still remain dissatisfied with our final response, you can ask the Financial Ombudsman Service to review your complaint. The Financial Ombudsman Service provides a free independent service for customers to resolve disputes with financial firms.</p>
          <p>The Financial Ombudsman Service will only deal with your complaint if you have given us the opportunity to put matters right, so please contact us first and we will do all we can to help you.</p>
          <p>You may refer a complaint up to six months from the date of our final written response.</p>

          <h2>How to contact the Financial Ombudsman Service</h2>
          <p><strong>By post:</strong></p>
          <p>Financial Ombudsman Service
            <br />Exchange Tower
            <br />London
            <br />E14 9SR
          </p>
          <p><strong>Telephone:</strong> 0800 0234567 or 0300 1239123 from a mobile.</p>
          <p><strong>Website:</strong> <a href="https://www.financial-ombudsman.org.uk/" target="_blank">www.financial-ombudsman.org.uk</a></p>

        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from '../../Sections/Footer'
import Header from '../../Sections/Header'
import {branding} from "@/branding";

export default {
  components: {
    Header,
    Footer
  },
  data () {
    return {
      branding
    }
  }
}
</script>

<style lang="scss">
.complaints {
  color: #000;
  margin-top: 100px;
  margin-bottom: 40px;
  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  table {
    width: 50%;
    margin-left: 10%;
    margin-bottom: 1rem;

    @media (max-width: 599px) {
      width: 100%;
      margin-left: 0;
    }

    tr {
      word-break: break-word;
    }

    td {
      padding: 0.5rem;
      border: 1px solid;
    }

    td:first-of-type {
      min-width: 30%;
    }
  }

  .bold {
    font-weight:bold;
  }

}
</style>
