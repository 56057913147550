import Main from './Views/Home'
import PrivacyPolicy from './Views/Policy/PrivacyPolicy'
import ThankYou from "./Views/ThankYou";
import TermsAndConditions from "@/Views/Policy/TermsAndConditions";
import CookiePolicy from "./Views/Policy/CookiePolicy";
import ComplaintPolicy from './Views/Policy/ComplaintsPolicy';
import Adverse from "@/Views/Adverse.vue";
import Remortgage from "@/Views/Remortgage.vue";

const root = "";

export const routes = [
    {
        name: "Home",
        path: root,
        component: Main
    },
    {
        name: "Adverse",
        path: `${root}/adverse`,
        component: Adverse
    },
    {
        name: "Remortgage",
        path: `${root}/remortgage`,
        component: Remortgage
    },
    {
        path:`${root}/thank-you`,
        component: ThankYou
    },
    {
        name: "Thank You",
        path: `${root}/thank-you`,
        component: ThankYou
    },
    {
        name: "Cookies",
        path: `${root}/cookies`,
        component: CookiePolicy
    },
    {
        name: "Privacy",
        path: `${root}/privacy`,
        component: PrivacyPolicy
    },
    {
        name: "Terms",
        path: `${root}/terms`,
        component: TermsAndConditions
    },
    {
        name: "Complaints",
        path: `${root}/complaints`,
        component: ComplaintPolicy
    },

];
