<template>
  <div v-observe-visibility="{
    callback: visibilityChanged,
    once: true,
    throttle: 100
    }"
    class="lending-counter text-center d-flex flex-column align-items-start">
    <span class="spacer"></span>
    <div class="content w-100 d-flex flex-column align-items-center pt-3">
      <h2>We're lending</h2>
      <h3 class="font-weight-bold">&pound;{{ currentLendingAmount.toLocaleString() }}</h3>
    </div>
    <span class="spacer"></span>
  </div>
</template>

<script>
    export default {
      data() {
          return {
            amountStart: 62730842,
            amountIncrease: 137970,
            startDays: 18095,
            days: 0,
            currentLendingAmount: 0,
            totalLendingAmount: 0
          }
      },
      methods: {
        visibilityChanged(visible) {
          if (visible && this.totalLendingAmount == 0) {
            this.totalLendingAmount =
                this.amountStart + this.getDays() * this.amountIncrease;
            this.animateLendingAmount(this.totalLendingAmount);
          }
        },
        getDays() {
          this.days = new Date().getTime() / (1000 * 24 * 60 * 60);
          return parseInt(this.days) - this.startDays;
        },
        animateLendingAmount(val) {
          const obj = {n: this.currentLendingAmount};
          this.$anime({
            targets: obj,
            n: val,
            round: 1,
            duration: 3000,
            easing: "linear",
            update: () => {
              this.currentLendingAmount = obj.n;
            }
          });
        },

      }
  }
</script>

<style lang="scss" scoped>

.lending-counter {
  --original-width: 241;
  --original-height: 296;
  --counter-width: 220;
  color:white;
  background-image: url('~@/assets/lendingcounter.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  width:calc(1px * var(--counter-width));
  height:calc(1px * ((var(--original-height) / var(--original-width)) * var(--counter-width)));
  //aspect-ratio: calc(296 / var(--counter-width));
}

.lending-counter h2,
.lending-counter h3 {
  font-size:1.5em;
}

.lending-counter .content {
  flex: 1 0 auto;
}

.lending-counter span.spacer:first-child {
  flex: 1 1 35% ;
}

.lending-counter span.spacer:last-child {
  flex: 1 1 65% ;
}


@media (max-width: 767px){
  .lending-counter {
    --counter-width: 175;
  }
  .lending-counter h2,
  .lending-counter h3 {
    font-size:1.2em;
  }
  .lending-counter span.spacer:first-child {
    flex: 1 1 30% ;
  }

  .lending-counter span.spacer:last-child {
    flex: 1 1 70% ;
  }

}

@media (max-width: 426px){
  .lending-counter {
    --counter-width: 150;
  }
  .lending-counter h2,
  .lending-counter h3 {
    font-size:1em;
  }
  .lending-counter span.spacer:first-child {
    flex: 1 1 25% ;
  }

  .lending-counter span.spacer:last-child {
    flex: 1 1 75% ;
  }

}

</style>
