<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero

        title="Expert advice on bad credit mortgages."
        leading="Adverse credit mortgages, are designed for individuals who have experienced financial difficulties in the past. These specialist products cater to borrowers with credit issues such as arrears, defaults or ..."
        :points="[
            `Poor credit history`,
            `County Court Judgments (CCJs)`,
            `Individual Voluntary Arrangements (IVAs)`,
            `Debt management plans`,
            `Bankruptcy within the last six years`
            ]"
    />
    <TrustPilot />
    <InfoSection
        image="happy-laptop.png"
        title="Understanding Adverse Credit Mortgages"
        :swap="true"
    >
      <h5><strong>Higher Interest Rates</strong></h5>
      <ul>
        <li>Lenders perceive borrowers with poor credit histories as higher risk, which is typically reflected in higher interest rates.</li>
        <li>Over time, as you demonstrate reliable repayment behaviour, you may become eligible for remortgaging at more favourable rates.</li>
      </ul>
      <h5><strong>Specialist Lenders</strong></h5>
      <ul>
        <li>Unlike mainstream banks, specialist lenders are more flexible and willing to consider applications from individuals with adverse credit. </li>
        <li>These lenders assess your overall financial situation, not just your credit score.</li>
      </ul>
      <h5><strong>Deposit Requirements</strong></h5>
      <ul>
        <li>You may be required to provide a larger deposit compared to those with good credit. A deposit of 20-30% is not uncommon.</li>
      </ul>
      <p><strong>Using a specialist mortgage broker can significantly enhance your chances of securing a mortgage, especially if you have a poor credit history.</strong></p>
    </InfoSection>

    <InfoSection
        image="family"
        title=" Specialist Remortgages: Refinancing Your Mortgage with Bad Credit"
    >
      <p>Refinancing your mortgage, also known as remortgaging, involves taking out a new mortgage to replace your existing one. This can be a strategic move for various reasons, including securing a better interest rate, consolidating debt, or accessing equity in your home. However, if you have bad credit, the process can be more challenging. Here&rsquo;s a detailed look at specialist remortgages and refinancing with bad credit.</p>

      <h4>What is a Specialist Remortgage?</h4>

      <p>A specialist remortgage is designed for individuals with adverse credit histories, including those with:</p>

      <ul>
        <li>Arrears: Missed mortgage payments.</li>
        <li>Defaults: Failed to meet the terms of a credit agreement.</li>
        <li>County Court Judgments (CCJs): Court orders for unpaid debts.</li>
        <li>Individual Voluntary Arrangements (IVAs): Agreements to pay back debts over time.</li>
        <li>Debt Management Plans (DMPs): Arrangements to pay back debts with a more manageable schedule.</li>
        <li>Bankruptcy: Legal status of being unable to repay debts.</li>
      </ul>

      <p>Specialist lenders cater to these individuals, offering remortgage products that consider the unique financial situations of those with poor credit.</p>
    </InfoSection>

    <InfoSection
        image="sorting-boxes"
        title="Benefits of Specialist Remortgages"
        :swap="true"
    >
      <ul>
        <li><strong>Access to Equity:</strong>
          <p>You can release equity tied up in your property, which can be used for home improvements, debt consolidation, or other financial needs.</p>
        </li>
        <li><strong>Improved DISPOSBALE INCOME:</strong>
          <p>By consolidating high-interest debts into your mortgage, you can potentially reduce your overall monthly outgoings, improving cash flow.</p>
        </li>
        <li><strong>Stabilizing Finances:</strong>
          <p>Refinancing can provide more manageable repayment terms, helping you stabilize your finances and improve your credit score over time.</p>
        </li>
      </ul>
    </InfoSection>

    <InfoSection
        image="home-keys"
        title="Considerations When Refinancing with Bad Credit"
    >
      <ul>
        <li><strong>Higher Interest Rates:</strong>
          <p>Specialist remortgages often come with higher interest rates due to the increased risk perceived by lenders. However, these rates can still be lower than those of unsecured high-interest debts you might be consolidating.</p>
        </li>
        <li><strong>Fees and Costs:</strong>
          <p>There may be additional fees, such as arrangement fees, valuation fees, and legal costs. These can add up and should be factored into your decision-making process.</p>
        </li>
        <li><strong>Loan-to-Value (LTV) Ratio:</strong>
          <p>Lenders will consider your LTV ratio, which is the amount of your mortgage compared to the value of your home. A lower LTV ratio (i.e., having more equity in your home) can improve your chances of approval and better terms.</p>
        </li>
        <li><strong>Credit Improvement:</strong>
          <p>While refinancing with bad credit is possible, it’s also crucial to work on improving your credit score. Consistent, timely payments and reducing outstanding debts can positively impact your creditworthiness.</p>
        </li>
      </ul>
    </InfoSection>

    <InfoSection
        image="home-alt"
        title="Steps to Secure a Specialist Remortgage"
        :swap="true"
    >

      <ul>
        <li><strong>WE Evaluate Your Financial Situation</strong>:
          <p>Assess your current financial status, including your credit score, outstanding debts, and income stability. Understanding your financial landscape is the first step toward refinancing.</p>
        </li>
        <li><strong>WE Research Specialist Lenders</strong>:
          <p>Identify lenders who specialize in adverse credit remortgages. These lenders are more likely to consider your application despite your credit history.</p>
        </li>
        <li><strong>BENEFITS Specialist Mortgage Broker</strong>:
          <p>A broker with experience in bad credit remortgages can provide valuable advice, identify suitable lenders, and help you navigate the application process.</p>
        </li>
      </ul>

    </InfoSection>

    <InfoSection
        image="business"
        title="Conclusion"
    >
      <p>Refinancing your mortgage with bad credit is challenging but achievable with the right approach. Specialist remortgages offer a viable solution for those with adverse credit histories, providing opportunities to stabilize finances, access equity, and potentially secure better terms. Working with a specialist mortgage broker can enhance your chances of approval and help you navigate the complexities of the remortgaging process.</p>
    </InfoSection>




    <HowItWorks/>
    <Contact />
    <Lenders />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import InfoSection from "../Sections/InfoSection";
import Contact from '../Sections/Contact.vue'
import Lenders from '../Sections/Lenders.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";

export default {
  name: 'Adverse',
  components: {
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Lenders,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>
