<template>
  <div>
    <div class="container-fluid lenderRow mt-4 mb-4">
      <div class="container d-flex justify-content-around lenderRow align-items-center flex-wrap">
        <img src="@/assets/lenders/united-trust-bank.jpg" alt="United Trust Bank">
        <img src="@/assets/lenders/together.jpg" alt="Together Money">
        <img src="@/assets/lenders/central-trust.jpg" alt="Central Trust">
        <img src="@/assets/lenders/norton-home-loans.jpg" alt="Norton Home Loans">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.lenderRow img {
  height: auto;
  max-width: 150px;
}
.lenderRow img {
  margin: 10px 10px 10px 0px;
}
</style>