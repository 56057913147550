<template>
  <div/>
</template>
<script>
import { branding } from "@/branding";

const loader = new Promise((resolve, reject) => {
        if (window.ClickAppForm) {
            resolve(window.ClickAppForm);
            return;
        }
        const script = document.createElement("script");
        script.src = process.env.VUE_APP_CLICK_APPLY_WIDGET;
        script.crossOrigin = "anonymous";
        script.onload = () => {
            resolve(window.ClickAppForm);
        };
        script.onerror = reject;
        document.body.appendChild(script);
    });

    export default {
        props: {
            mode: {
              type: String,
              required: true,
              default: "Specialist"
            },
            productType: {
              type: String,
              required: false,
              default: null
            }
        },
        async mounted() {
            //console.log(this.mode);
            const tracking = await window.ClickAppTrack;
            tracking.applicationURL = location.href;
            await loader.then(ClickAppForm => {
                ClickAppForm.default(this.$el, {
                    media: process.env.VUE_APP_MEDIA_CODE,
                    phoneNumber: branding.phone,
                    documentURLs: {
                        privacyNotice: "/privacy",
                        terms: "/terms"
                    },
                    tracking: tracking,
                    initialValues: {
                      ...this.$route.query,
                      "residentialStatus": "homeowner",
                      "productType": this.productType !== null ? this.productType : undefined
                    },
                    minLoanAmount: 10000,
                    maxLoanAmount: 20000000,
                    mode: this.mode,
                    target: "specialist",
                    believe: true,
                    redirectOnComplete: "/thank-you"
                });
            }).then(() => window.addEventListener("message", event => {
               if (event.data.type === "scroll") {
                  window.scrollTo(0, 0);
                }
            }));
        }
    };
</script>
