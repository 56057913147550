<template>
  <div
      class="hero-area pb-0 pb-sm-4 mb-3 mb-sm-0"
      :class="{ 'form' : displayAppForm }"
      :style="`background-image: url(${require(`@/assets/heros/${image}`)})`"
  >
    <div class="container" :class="{ 'container-fluid' : displayAppForm }">
      <AppForm
          :mode="appFormMode"
          :product-type="appFormProductType"
          class="hero-area__app-form"
          v-if="displayAppForm" />
      <div class="hero-area__app-form hero-area__app-form-selector pt-5" v-else-if="displayAppFormSelection">
        <div class="row">
          <div class="col12 col-md-6">
            <h1 class="title">What type of mortgage are you looking for?</h1>
            <div class="hero-area__app-form-selector__buttons">
              <button :class="`btn btn-xl green align-middle d-flex flex-column align-items-center justify-content-center ctaButton my-3 px-5 py-3`"
                      type="button"
                      name="button"
                      v-for="(option) in forms"
                      :key="option.value"
                      @click="showAppForm(option.value)"
              >{{ option.label }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row pt-3 pt-sm-5 hero-area__content d-flex">
          <div class="col-12 col-md-9 col-lg-7 col-xl-6">
            <Section
                :title="title"
                :leading="leading"
                :points="points"
                bullet="tick"
                parent-class="hero-area__section"
            >
              <apply-router @showAppForm="showAppForm" />
            </Section>
          </div>
          <!--          <div :class="`col-12 col-md-5 offset-md-1 hero-area__background d-flex flex-column align-items-center justify-content-center`">-->

          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppForm from '../components/AppForm'
import {Bus} from '../bus'
import ApplyRouter from "../components/Apply/Apply";
import Section from "../components/Section";

export default {
  components: {
    ApplyRouter,
    AppForm,
    Section
  },
  props: {
    title: {
      type:String,
      required: true
    },
    leading: {
      type:String,
      required: true
    },
    points: {
      default: () => [],
      type: Array
    },
    image: {
      default: "home.png",
      type: String
    }
  },
  created() {
    Bus.$on("headerLogoClicked", () => {
      this.hideAppForm();
    });
  },
  data() {
    return {
      displayAppForm: false,
      displayAppFormSelection: false,
      appFormMode: 'specialist',
      appFormProductType: 'select',
    }
  },
  computed: {
    forms() {
      if (this.$route.name === 'Adverse') {
        return [
          {value: "remortgage", label: 'Remortgage', mode: 'remortgage'},
          {value: "homePurchase", label: 'Home Purchase', mode: 'homePurchase'},
        ]
      } else {
        return [
          { value: "bridgingLoan", label: 'Bridging Finance', mode: 'specialist' },
          { value: "purchaseBuyToLet", label: 'Buy-to-let Mortgage', mode: 'specialist' },
          { value: "commercial", label: 'Commercial Mortgage', mode: 'specialist' },
          { value: "purchaseResidential", label: 'Residential Mortgage', mode: 'specialist' }
        ]
      }
    }
  },
  methods: {
    showAppForm(mode) {
      if (mode === 'select') {
        this.displayAppFormSelection = true;
      } else {
        this.appFormProductType = mode;
        this.appFormMode = this.forms.find(form => form.value === mode).mode;
        this.displayAppForm = true;
      }
      window.scrollTo(0, 0);
    },
    hideAppForm() {
      this.displayAppForm = false;
      this.displayAppFormSelection = false;
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style type="scss">

.container.container-fluid {
  max-width: 100%;
  padding: 0 0 3rem;
}

/* ------------------------------------------------------------------------------- */
/* Tenant Pop up */
/* ------------------------------------------------------------------------------- */

.tenant-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
  align-items: center;
  justify-content: center;
  color:black;
}

.tenant-popup div {
  margin: 1em;
  background: white;
  padding: 2em;
  border-radius: 0.5em;
}

.tenant-popup button {
  padding: 10px;
  width: 100%;
}

.tenant-popup.open {
  display: flex;
}

/* ------------------------------------------------------------------------------- */
/* Selector Buttons */
/* ------------------------------------------------------------------------------- */

.hero-area__app-form-selector__buttons {
  flex-direction: column;
  margin-top:10px;
  justify-content: space-between;
}

.ctaButton {
  font-size:1rem;
  font-weight: bold;
  border-radius: 1rem;
  width:100%;
}

/* ------------------------------------------------------------------------------- */
/* Cta Buttons */
/* ------------------------------------------------------------------------------- */

.ctaButton.green {
  background-color: #56B27F;
  border-color: #56B27F;
  color:white;
&:active,
&:focus,
&:active:focus {
   background-color: #56B27F !important;
   border-color: #56B27F !important;
   box-shadow: 0 0 0 0.2rem rgba(86, 178, 127, 0.5) !important;
 }
}

.ctaButton.blue {
  background-color: #17174C;
  border-color: #17174C;
&:active,
&:focus,
&:active:focus {
   background-color: #17174C !important;
   border-color: #17174C !important;
   box-shadow: 0 0 0 0.2rem rgba(23, 23, 76, 0.5) !important;
 }
}

.ctaButton.red {
  background-color: #dd1d42;
  border-color: #dd1d42;
&:active,
&:focus,
&:active:focus  {
   background-color: #dd1d42 !important;
   border-color: #dd1d42 !important;
   box-shadow: 0 0 0 0.2rem rgba(221, 129, 66, 0.5) !important;
 }
}

/* ------------------------------------------------------------------------------- */
/* Hero (Desktop) */
/* ------------------------------------------------------------------------------- */

.hero-area {
  min-height: 70vh;
}

.hero-area.form {
  background: none !important;
}


/* up to 767px */
@media (max-width: 767px) {
  .hero-area {
    background: linear-gradient(330deg, #5B4E99 0%, rgba(31,36,74,1) 40%) !important;
  }
}

.hero-area__section h1,
.hero-area__section p,
.hero-area__section div,
.hero-area__app-form h1  {
  color: white !important;
}

.hero-area__section h1,
.hero-area__app-form h1 {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.hero-area__app-form.bg-box {
  background: rgba(255, 255, 255, 0.75);
  border-radius: 1rem;
  max-width: 600px;
  margin: 0 auto;
}

</style>
